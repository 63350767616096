@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Varela+Round:400,700');

$primary-font:'Varela Round', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

body, p, input {
  margin: 0;
  font-weight: 400;
  font-family: $primary-font;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $secondary-font;
  letter-spacing: 0rem;
}

strong {
  font-family: $secondary-font;
}
